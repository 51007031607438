import React, {useState, ChangeEvent, MouseEvent} from 'react'
import { iSeleccion } from '../controllers/class.tarifas';

import 'bootstrap/dist/css/bootstrap.min.css';

// TODO: Esto de la tabla
const defaultSelect = 'UD - Tarifa doméstica';

export const FormCalculo = ( { setSeleccion } : any ) => {

    // State interno del form con la misma estructura que el setSelection

    const [formValues, setFormValues] = useState<iSeleccion>({
        tipo :  defaultSelect,
        consumo : 0,
        guardar : false
    });


    const handleSubmit = (e : React.SyntheticEvent) =>{
        e.preventDefault();

        // Aquí se dispara el renderizado en App

        setSeleccion( formValues );
    }

    const handleChange = (e : ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement> ) =>{
        setFormValues( { ...formValues, [e.target.name] : e.target.value} );
    }

    const handleBoton = ( e : MouseEvent<HTMLButtonElement>) => {

        // Antes del submit. Determino que botón se pulsó.

        setFormValues( { ...formValues, guardar: (e.currentTarget.name === 'guardar')?true:false } );
    }

    return (

        <form  onSubmit={ handleSubmit }>
        <div className="form.group border p-2">

            <label htmlFor="tipo" className="col-form-label col-form-label-sm">Tipo de tarifa :</label>
            <select name="tipo" 
                    id="tipo" 
                    className="form-control  col-sm-3  form-control-sm"
                    value={formValues.tipo} 
                    onChange = { handleChange }>
                <option>{defaultSelect}</option>
                <option>UI - Tarifa industrial</option>
            </select>

            <label htmlFor="consumo" className="col-form-label col-form-label-sm">Consumo en m<sup>3</sup> :</label>
            <input  name="consumo" 
                    id="consumo" 
                    type="number" 
                    className="form-control col-sm-2 form-control-sm"
                    min= "0"
                    max="999999"
                    value={formValues.consumo}
                    onChange= { handleChange }/>
            
            <div className="py-2 d-flex justify-content-between">
            <button type="submit" onClick={handleBoton} name="calcular" className="btn btn-primary">Calcular</button>
            {/* <button type="submit" onClick={handleBoton} name="guardar"  className="btn btn-info">Guardar</button> */}
            </div>        
        </div>
        </form>

    )

}