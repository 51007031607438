import {useEffect,useState} from 'react';
import { CalculaTarifas, iDatosTarifas, iSeleccion, iDesgloseTarifa, iDesgloseIVA, iDesgloseFila } from '../controllers/class.tarifas';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface iCuerpo {
    tarifas : iDatosTarifas;
    seleccion : iSeleccion;
}

const locSep = 'de-DE'; // Si utilizamos es-ES el separador de miles empieza en un millón

const locSt2 : Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency', 
    currency: 'EUR',
    useGrouping: true
}

const locSt4 : Intl.NumberFormatOptions = {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
    style: 'currency', 
    currency: 'EUR'
}

interface iTramos {

    tramos : iDesgloseFila[];
}


interface iFactura {

    desgloseTarifas : iDesgloseTarifa[];
    desgloseIVAs    : iDesgloseIVA[];
    tbases          : number;
    total           : number;

}




export const Desglose = (   { tramos } : iTramos   ) => {
return (
        <tr>
            <td colSpan={3}>
            <table className="table table-bordered table-sm">
                <thead>
                    <tr>
                    <th className="text-sm-right small">Desde</th>
                    <th className="text-sm-right small">Hasta</th>
                    <th className="text-sm-right small">Precio m<sup>3</sup></th>
                    <th className="text-sm-right small">m<sup>3</sup></th>
                    <th className="text-sm-right small">Importe</th>
                    </tr>
                </thead>
                <tbody>

                {
                    tramos.map( (x : iDesgloseFila) => { return (
                        <tr>
                        <td className="text-right small">{x.desde}</td>
                        <td className="text-right small">{x.hasta}</td>
                        <td className="text-right small">{x.pvm3.toLocaleString(locSep,locSt4)}</td>
                        <td className="text-right small">{+x.m3}</td>
                        <td className="text-right small">{x.importe.toLocaleString(locSep,locSt2)}</td>
                        </tr>
                    )})
                }


                </tbody>
            </table>
            </td>
        </tr>
    )
}

export const Cuerpo = ( { tarifas,seleccion } : iCuerpo) => {

    const [factura, setFactura] = useState<iFactura>({
        desgloseTarifas : [],
        desgloseIVAs : [],
        
        tbases : 0,
        total : 0
    });

    useEffect( () => {

        const z = new CalculaTarifas(tarifas);
        z.desglose(seleccion.tipo.substring(0,2),seleccion.consumo);

        if (!z.error) {
            
            setFactura({
            
            desgloseTarifas : z.desgloseTarifas,
            desgloseIVAs : z.desgloseIVAs,
            tbases : z.tbases,
            total : z.total
            });

        
        };

    },[seleccion,tarifas]);



    return (
        <main>
            <div className="container-fluid h-100 bg-light py-2 mt-1 mb-4">
                <h5>{seleccion.tipo}</h5>
                <h5>Consumo : <strong>{+seleccion.consumo}</strong> m<sup>3</sup></h5>
                <table className="table table-sm">
                    <thead>
                        <tr>
                        <th>Concepto</th>
                        <th className="text-right">Imp. Fijo</th>
                        <th className="text-right">Imp. Tramos</th>
                        <th className="text-right">% IVA</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            factura.desgloseTarifas.map( (x) => {return (
                                <>
                                    <tr>
                                    <td className="text-primary"><small>{x.texto}</small></td>
                                    <td className="text-right text-primary">{(x.impFijo !== 0)   ? x.impFijo.toLocaleString(locSep,locSt2)   : ''}</td> 
                                    <td className="text-right text-primary">{(x.impTramos !== 0) ? x.impTramos.toLocaleString(locSep,locSt2) : ''}</td>
                                    <td className="text-right text-primary">{(x.tipoIVA !== 0)   ? x.tipoIVA              : ''}</td>
                                    </tr>
                                { (x.tramos.length > 0) && <Desglose tramos={x.tramos}/> }
                                </>
                        )})
                        }

                    <tr>
                        <td colSpan={4}></td>
                    </tr>

                    </tbody>
                </table>
                <div className="col-sx-6">
                <table className="table w-75">
                    <thead>
                        <tr>
                        <th className="text-right small">% IVA</th>
                        <th className="text-right small">Base Imponible</th>
                        <th className="text-right small">Importe IVA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            factura.desgloseIVAs.map( (x) => {return (
                                <>
                                    <tr>
                                    <td className="text-right small">{ x.tipoIVA.toFixed(0)}</td> 
                                    <td className="text-right small">{(x.base    !== 0) ? x.base.toLocaleString(locSep,locSt2) : ''}</td>
                                    <td className="text-right small">{(x.impIVA  !== 0) ? x.impIVA.toLocaleString(locSep,locSt2)              : ''}</td>
                                    </tr>
                                </>
                        )})
                        }
                        <tr>
                            <td colSpan={4}></td>
                        </tr>

                        <tr>
                        <td className="text-left text-primary">BASE</td> 
                        <td className="text-right text-primary">{factura.tbases.toLocaleString(locSep,locSt2)}</td>
                        <td className="text-right"></td>
                        </tr>

                        <tr>
                        <td className="text-left text-primary">TOTAL</td> 
                        <td className="text-right text-primary"><strong>{factura.total.toLocaleString('de-DE',locSt2)}</strong></td>
                        <td className="text-right"></td>
                        </tr>

                    </tbody>
                </table>      
                </div>         




            </div>
        </main>
    )
}
