import 'bootstrap/dist/css/bootstrap.min.css';

interface iCabecera {
    ayto : string;
    titulo : string;
    logo : string;
}

export const Cabecera = ({ayto,titulo,logo}: iCabecera) => {
    return (
        <header>
              <div className="container-fluid bg-light mt-2">
                <img src={ logo } alt = { ayto} width={80}/>
                <h4>{ ayto }</h4>
                <small>{ titulo }</small>
                <hr/>
              </div>
        </header>
    )
}
