import React, {useState, useEffect} from 'react';
import { cargaTarifasObj, iDatosTarifas, iSeleccion } from '../controllers/class.tarifas';
import { FormCalculo } from './FormCalculo';
import { Cabecera } from './Cabecera';
import { Cuerpo } from './Cuerpo'

import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../static/escudo.svg'

const ayto   = 'Ajuntament de Benissa';
const titulo = 'Cálculo del desglose de tarifas basado en el consumo';

export const App = () => {

    
    // Estructura de las tarifas necesarias para el cálculo

    const [calTarifas, setCalTarifas] = useState<iDatosTarifas>({
        err : false,
        msg : '',
        tipostarifa: [],
        tarifas: []
    });


    // Información obtenida del formulario

    const [seleccion, setSeleccion] = useState<iSeleccion>({
        tipo :  '',
        consumo : 0,
        guardar : false
    });

    // Cargo las tarifas una sola vez

    useEffect( () => {
        setCalTarifas( new cargaTarifasObj() );
    },[]);
    
    // const Print = () => (
    //     <>
    //     { !calTarifas.err && <Cabecera ayto={ayto} titulo={titulo} logo={logo}/> }
    //     { !calTarifas.err && (seleccion.tipo !== '') && <Cuerpo tarifas={calTarifas} seleccion={seleccion}/> }
    //     </>
    // );

    return (
        <div className="container-fluid min-width-700px">

              <Cabecera ayto={ayto} titulo={titulo} logo={logo}/>

              { !calTarifas.err && <FormCalculo setSeleccion={setSeleccion} /> }

              { !calTarifas.err && (seleccion.tipo !== '') && <Cuerpo tarifas={calTarifas} seleccion={seleccion}/> }
          
              {  calTarifas.err && <div className="alert alert-danger" role="alert"><small>Error en la carga de tarifas:</small>{calTarifas.msg}</div>}

        </div>
    )

    }


