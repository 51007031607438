import * as fs from 'fs';
import * as path from 'path';


import jTarifas from '../static/tarifas.json';

export interface iSeleccion {
    tipo    : string;
    consumo : number;
    guardar : boolean;
}

export interface iTiposTarifa {
    id    : string;
    nombre: string;
    codtarifas: number[];
}

export interface Tramo {
    desde: number;
    hasta: number;
    importe: number;
}

export interface iTarifa {
    codtarifa: number;
    nombre: string;
    impfijo: number;
    iva: number;
    tramos: Tramo[];
}

export interface iDatosTarifas {
    
    tipostarifa: iTiposTarifa[];
    tarifas: iTarifa[];

    err? : boolean;
    msg? : string;
}

export interface iDesgloseFila {
    desde    : number;
    hasta    : number;
    pvm3     : number;
    m3       : number;
    importe  : number; // TODO: redonly cálculo con getter (m3*pvm3) en una clase
}

export interface iDesgloseTarifa {

    texto : string;
    impFijo : number;
    impTramos : number;
    tipoIVA : number;
    tramos : iDesgloseFila[];
}

export interface iDesgloseIVA {
    tipoIVA : number;
    base    : number;
    impIVA  : number;
}



// Leerá desde la base de datos
export class cargaTarifasDB implements iDatosTarifas {
    public err = false;
    public msg = '';
    public tipostarifa : iTiposTarifa[] = [];
    public tarifas : iTarifa[] = [];
    
    constructor(/* para leer de la BD */) {};

}

// Leerá directamente del JSON por import
export class cargaTarifasObj implements iDatosTarifas {
    public err = false;
    public msg = '';
    public tipostarifa : iTiposTarifa[] = [];
    public tarifas : iTarifa[] = [];
    
    constructor() {

        try {
            
            const z = jTarifas;

            this.tipostarifa = z.tipostarifa;
            this.tarifas     = z.tarifas; 
        } catch (error) {
            if (error instanceof Error) this.msg = error.message;
            this.err = true;
        }

    };

}

// Leerá cargando el fichero JSON de disco
export class cargaTarifasJSON implements iDatosTarifas {

    public err = false;
    public msg = '';
    public tipostarifa : iTiposTarifa[] = [];
    public tarifas : iTarifa[] = [];
    
    constructor(ruta : string) {

        try {
            const z : iDatosTarifas = JSON.parse(fs.readFileSync(path.join(__dirname, ruta), 'utf8')); 
            this.tipostarifa = z.tipostarifa;
            this.tarifas     = z.tarifas; 
        } catch (error) {
            if (error instanceof Error) this.msg = error.message;
            this.err = true;
        }

    } 

}

export class CalculaTarifas {

    public datosTarifas    : iDatosTarifas;
    public desgloseTarifas : iDesgloseTarifa[];
    public desgloseIVAs    : iDesgloseIVA[];
    
    public tbases : number = 0;
    public total  : number = 0;
    
    public error           : string | null = null;


    constructor(datosTarifas : iDatosTarifas) {

        this.datosTarifas = datosTarifas;
        this.desgloseTarifas = [];
        this.desgloseIVAs = [];
    }

    public desglose(tipotarifa: string, consumo : number) {

        const tarifasEnTipo = this.datosTarifas.tipostarifa.find( x => x.id === tipotarifa);

        if (!tarifasEnTipo) {
            this.error = `No existe la tarifa: ${tipotarifa}`;
            return;
        }

        tarifasEnTipo.codtarifas.forEach( n => {

            let tarifa = this.datosTarifas.tarifas.find(x => x.codtarifa === n );
        
            if (tarifa) {

                let desgloseTarifa = {} as iDesgloseTarifa;

                desgloseTarifa.texto        = tarifa.nombre;
                desgloseTarifa.impFijo      = tarifa.impfijo;
                desgloseTarifa.tipoIVA      = tarifa.iva;
                desgloseTarifa.tramos       = [] as iDesgloseFila[];

                if (tarifa.impfijo) this.addBases(tarifa.impfijo,tarifa.iva);

                // console.log(`${tarifa.nombre.padEnd(40)}:  Importe fijo : ${tarifa.impfijo.toFixed(2).padStart(10)}        IVA : ${tarifa.iva.toFixed(2).padStart(10)}`);
        
                let m3 = consumo;
                let imptramos = 0;
                let m3tramo = 0;
        
        
                tarifa.tramos.forEach (t => {

                    m3tramo =  (t.hasta >= 999999?999999999:t.hasta)-t.desde+1;

                    let desgloseFila = {} as iDesgloseFila;


                    desgloseFila.desde      = t.desde;
                    desgloseFila.hasta      = t.hasta;
                    desgloseFila.pvm3       = t.importe;

                    if (m3 >= m3tramo) { 
                        m3 -= m3tramo;

                        desgloseFila.m3         = m3tramo;
                        desgloseFila.importe    = +(t.importe * m3tramo).toFixed(2);
                    }
                    else { 

                        desgloseFila.m3         = m3;
                        desgloseFila.importe    = +(t.importe * m3).toFixed(2);
                        m3 = 0;
                    }
                    
                    imptramos += desgloseFila.importe;
                    desgloseTarifa.tramos.push(desgloseFila);
                });
        
                if (imptramos) this.addBases(imptramos,tarifa.iva);

                desgloseTarifa.impTramos = imptramos;

                this.desgloseTarifas.push(desgloseTarifa);
        
            }
        
        });

        this.desgloseIVAs.forEach (x => {
            this.tbases += x.base; 
            this.total += (x.base+x.impIVA) });

    };

    private addBases(base: number, tipoIVA : number) {

        const i = this.desgloseIVAs.findIndex( x => x.tipoIVA === tipoIVA);
    
        // (i+1) pasa el -1 a 0 (false)
    
        if (i+1) {
    
            this.desgloseIVAs[i].base   += base;    
            this.desgloseIVAs[i].impIVA  = +(base*this.desgloseIVAs[i].tipoIVA/100).toFixed(2);    
    
        } else {
    
        this.desgloseIVAs.push(
            { 
                base,
                tipoIVA,
                impIVA  : +(base*tipoIVA/100).toFixed(2) 
            });
        }
    
    } 

}






